<template>

    <div class="scrollview-container container is-fluid animate__animated animate__animated animate__slideInDown"
        ref="scroll-baby">

        <div class="columns is-mobile is-centered is-variable is-7 is-vcentered pp">
            <div class="column is-narrow">
                <div class="hordiv"></div>
                <h1 class="medtit has-text-left">Spalování<br>odpadků</h1>
            </div>

            <div class="column is-narrow-tablet is-full-mobile">
                <img src="../assets/spalovani.jpg" />
            </div>
            <div class="column is-narrow">
                <b-icon icon="arrow-down" size="is-large" type="is-info">
                </b-icon>
            </div>
            </div>
             <div class="columns is-centered is-mobile is-multiline is-variable is-7 is-vcentered ">
            <div class="column  is-half-tablet is-full-mobile scrollable">
                <h2>Bezemisní spalování odpadu</h2>

                <i>Jedním z nejdostupnějších zdrojů pro výrobu elektrické
                    energie jsou tolik obávané odpady. Jejich dokonalým
                    zpracováním přitom kromě elektrické energie získáme i
                    čistší a zdravější životní prostředí – jedná se tak o
                    technologii, která má naprosto zásadní význam pro
                    budoucnost lidstva.</i>
                <br><br>
                <div class="content">

                    NEJČISTŠÍ METODA LIKVIDACE ODPADU JE PLAZMOVÁ
                    GASIFIKACE
                    Nejrozšířenějším způsobem nakládání s odpadem je
                    uložení na skládce – to je však velmi neekologický i
                    neekonomický způsob zpracování. Navíc skladování
                    odpadu problém likvidace odpadů ve skutečnosti neřeší –
                    pouze jej přehazuje jako horkou bramboru budoucí
                    generaci. Ekologické škody, které však takovým přístupem vznikají, jsou zcela fatální. Skládky navíc
                    nekontrolovatelně produkují nebezpečný metan a přispívají tak ke skleníkovému efektu.
                </div>
            </div>
            <div class="column is-full has-text-centered">
                <b-icon icon="arrow-down" size="is-large" type="is-info">
                </b-icon>
            </div>
            <div class="column  is-half-tablet is-full-mobile ">
                <h2>Plazmový gasifikátor</h2>

                <div class="content">

                    Podstatou procesu je totální destrukce odpadu na úroveň elementárních atomů. Organické a anorganické látky jsou
                    bezezbytku rozloženy při teplotě blízké povrchové teplotě slunce (4000 °C až 6000 °C). Rozdrcený a
                    rozemletý odpad
                    je likvidován v plazmovém reaktoru. Jedním z produktů procesu je tzv. slag, ze kterého se oddělují
                    kovy a slitiny
                    vhodné pro zpracování v metalurgii.
                </div>
            </div>
              <div class="column is-full has-text-centered">
                <b-icon icon="arrow-down" size="is-large" type="is-info">
                </b-icon>
            </div>
             <div class="column  is-half-tablet is-full-mobile">
                <h2>Depolymerizace plastu</h2>

                <div class="content">

                    Depolymerizační reaktor přijímá plast v kusech, jež rozemele a rozpustí. V průběhu je tvořen plyn, jež je zachytáván
                    a pohání generátor. Z průběhu depolymerizace vznikají velmi vzácné a potentní vedlejší prvky, jako je ropný vosk, ropná složka
                    a popílek. Vytvořená energie je připravena k použití, či uložení.
                </div>
                <br>
            <br>
            <br>
            <br>
            <br>
            </div>
            
        </div>


    </div>
</template>

<script>
    export default {
        name: 'veShopTopbar',
        title: "Bedýnky ze dřeva",
        data() {
            return {

            }
        },
        methods: {
            scrollX(e) {
                this.$refs['scroll-baby'].scrollBy({
                    left: e.deltaY
                });
            },
        },
        mounted() {
            this.$parent.darkmode = true;
        }


    }
</script>
<style>

</style>