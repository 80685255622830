<template>
     <div class="rt">
        <b-button tag="router-link" to="/waste-info" type="is-info" class="moreinfo animate__animated animate__zoomIn animate__delay-1s" icon-left="information" size="is-medium">Více info</b-button>
        <img class="is-hidden-mobile city animate__animated animate__slideInRight" :src="require('../assets/wasteb.svg')"/>
        <img class="is-hidden-mobile city animate__animated animate__zoomIn animate__delay-1s" :src="require('../assets/waste.svg')"/>
        <router-link tag="img" to="/waste-info"  class="is-hidden-tablet city animate__animated animate__slideInRight" :src="require('../assets/mwasteb.svg')"/>
        <router-link tag="img" to="/waste-info"  class="is-hidden-tablet city animate__animated animate__zoomIn animate__delay-1s" :src="require('../assets/mwaste.svg')"/>
    </div>
</template>

<script>
    export default {
        name: 'veShopTopbar',
        title: "Bedýnky ze dřeva",
        methods: {
        },
        computed: {
           
        },
        data() {
            return {
            }
        },
        mounted() {
            this.$parent.darkmode = false;
        }



    }
</script>
<style>
    .moreinfo {
        font-family: "IBM Plex Sans", sans-serif;
        font-weight: bold;
        position:absolute;
        top:4rem;
        cursor:pointer;    
        z-index: 5000;
        right: 4rem;
    }
    @media (max-width: 768px) {
         .moreinfo {
            display:none;
        }
    }
</style>