<template>

    <div class="rt">
        
        <img class="is-hidden-mobile city animate__animated animate__slideInRight" :src="require('../assets/lightsb.png')"/>
        <img class="is-hidden-mobile city animate__animated animate__zoomIn animate__delay-1s" :src="require('../assets/lights.svg')"/>
        <img class="is-hidden-tablet city animate__animated animate__slideInRight" :src="require('../assets/mlightsb.svg')"/>
        <img class="is-hidden-tablet city animate__animated animate__zoomIn animate__delay-1s" :src="require('../assets/mlights.svg')"/>
    </div>
</template>

<script>
    export default {
        name: 'veShopTopbar',
        title: "Bedýnky ze dřeva",
        methods: {
            
        },
        computed: {
            newsc: function () {
                var ret = JSON.parse(JSON.stringify(this.news.items));
                ret.shift();
                return ret
            }
        },
        data() {
            return {
            }
        },
        mounted() {
            this.$parent.darkmode = true;
        }



    }
</script>
<style>
.city {
    position: absolute;
    width: 100%;
    max-width: 1400px;
    height: auto;
    bottom: 0;
}
    .rt
    {
        position: relative;
        height:100%;
        display: flex;
  align-items: center;
  justify-content: center;
    }
    @media (max-width: 768px) {
        .page-back-cc {
            width: 103vw;
            height: 103vh;
            margin-left: -36px;
            margin-top: -130px;
            z-index: -1;
        }
    }
</style>