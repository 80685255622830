import Vue from 'vue'
import VueRouter from 'vue-router'
import MainPage from '../views/MainPage.vue'
import ContactPage from '../views/ContactPage.vue'
import NotFound from '../views/NotFound.vue'
import Partners from '../views/Partners.vue'
import References from '../views/References.vue'
import About from '../views/About.vue'
import Ecology from '../views/Ecology.vue'
import CleanCity from '../views/CleanCity.vue'
import CleanEnergy from '../views/CleanEnergy.vue'
import CleanEnergy2 from '../views/CleanEnergy2.vue'
import Ethics from '../views/Ethics.vue'
import Company from '../views/Company.vue'
import CompanyStructure from '../views/CompanyStructure.vue'
import Waste from '../views/Waste.vue'
import Waste2 from '../views/Waste2.vue'
//import Team from '../views/Team.vue'
import WirelessLighting from '../views/WirelessLighting.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'OakTrade',
    component: MainPage
  },
  {
    path: '/kontakt',
    name: 'OakTradeContactPage',
    component: ContactPage
  },{
    path: '/firma',
    name: 'OakTradeCompany',
    component: Company
  },{
    path: '/team',
    name: 'OakTradeTeam',
    component: CompanyStructure
  },{
    path: '/eticky-kodex',
    name: 'OakTradeEthics',
    component: Ethics
  },
  {
     path: '/dotaz',
     beforeEnter() {location.href = 'mailto:cach@oak-trade.cz?subject=Dotaz%20z%20webu%20OAK%20trade'}
},
  {
    path: '/waste',
    name: 'OakTradeEcoWaste',
    component: Waste
  },
  {
    path: '/waste-info',
    name: 'OakTradeEcoWaste2',
    component: Waste2
  }
  ,
  {
    path: '/ekologie',
    name: 'OakTradeEco',
    component: Ecology
  }, {
    path: '/clean-city',
    name: 'OakTradeEcoCleanCities',
    component: CleanCity
  },{
    path: '/wireless-lighting',
    name: 'OakTradeEcoWirelessLighting',
    component: WirelessLighting
  },{
    path: '/clean-energy',
    name: 'OakTradeEcoCleanEnergy',
    component: CleanEnergy
  },{
    path: '/energy-info',
    name: 'OakTradeEcoCleanEnergy2',
    component: CleanEnergy2
  },
  {
    path: '/partneri',
    name: 'OakTradePartners',
    component: Partners
  },
  {
    path: '/reference',
    name: 'OakTradeReferences',
    component: References
  }
  ,
  {
    path: '/o-nas',
    name: 'OakTradeAbout',
    component: About
  }
  ,
  {
    path: '*',
    name: 'OakTradeHelp',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/*
class Counter {
  constructor(value) {
    this.technical = value
  }
}*/


export default router
