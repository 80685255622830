<template>
    <div class="landing container is-fluid">
        <div class="page-back-l"></div>

        <div class="columns is-mobile is-hidden-mobile pops is-centered is-mobile">
            <div class="column is-narrow">
                <h1 class="has-text-dark">
                    Pevná

                </h1>
                <div class="landingtext">
                    <p>Na poli energetických<br>
                        integračních řešení<br></p>
                    <p class="s2">Nechcete začít <br>
                        obchodovat ještě<br> dnes?</p>
                </div>
                <br>
                <router-link tag="div" to="/kontakt">
                    <b-button type="is-primary" class="linkbtn is-radiusless">Kontakt</b-button>
                </router-link>
                <br>
                <div class="columns is-mobile is-variable is-1">
                    <div class="column is-narrow">
                        <router-link tag="a" to="/clean-city">
                            <b-icon type="is-info" size="is-medium" icon="city-variant"></b-icon>
                        </router-link>
                    </div>
                    <div class="column is-narrow">
                        <router-link tag="a" to="/clean-energy">
                            <b-icon type="is-info" size="is-medium" icon="solar-power"></b-icon>
                        </router-link>
                    </div>
                    <div class="column is-narrow">
                        <router-link tag="a" to="/wireless-lighting">
                            <b-icon type="is-info" size="is-medium" icon="coach-lamp"></b-icon>
                        </router-link>
                    </div>
                    <div class="column is-narrow">
                        <router-link tag="a" to="/waste">
                            <b-icon type="is-info" size="is-medium" icon="delete-empty"></b-icon>
                        </router-link>
                    </div>
                    <div class="column is-narrow is-hidden">
                        <router-link tag="a" to="/e-mobility">
                            <b-icon type="is-info" size="is-medium" icon="car-electric"></b-icon>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="column is-narrow">
                <h1 class="has-text-dark"><span>spolupráce</span></h1>
            </div>
        </div>
        <div class=" is-hidden-tablet pops has-text-centered">

            <h1 class="has-text-dark has-text-centered fss">
                Pevná <span>spolupráce</span></h1>

            <div class="landingtext  has-text-centered">
                <p>Na poli energetických integračních řešení</p>
                <p class="s2">Nechcete začít obchodovat ještě dnes?</p>
            </div>
            <br>
            <router-link tag="div" to="/kontakt">
                <b-button type="is-primary" class="linkbtn is-radiusless">Kontakt</b-button>
            </router-link>
 <br>
                <div class="columns is-centered is-mobile is-variable is-1">
                    <div class="column is-narrow">
                        <router-link tag="a" to="/clean-city">
                            <b-icon type="is-dark" size="is-medium" icon="city-variant"></b-icon>
                        </router-link>
                    </div>
                    <div class="column is-narrow">
                        <router-link tag="a" to="/clean-energy">
                            <b-icon type="is-dark" size="is-medium" icon="solar-power"></b-icon>
                        </router-link>
                    </div>
                    <div class="column is-narrow">
                        <router-link tag="a" to="/wireless-lighting">
                            <b-icon type="is-dark" size="is-medium" icon="coach-lamp"></b-icon>
                        </router-link>
                    </div>
                    <div class="column is-narrow">
                        <router-link tag="a" to="/waste">
                            <b-icon type="is-dark" size="is-medium" icon="delete-empty"></b-icon>
                        </router-link>
                    </div>
                    <div class="column is-narrow">
                        <router-link tag="a" to="/e-mobility">
                            <b-icon type="is-dark" size="is-medium" icon="car-electric"></b-icon>
                        </router-link>
                    </div>
                </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'veShopTopbar',
        title: "Bedýnky ze dřeva",
        data() {
            return {

            }
        },
        mounted() {
            
            this.$parent.darkmode = false;
        }


    }
</script>
<style>
    .page-back-l {
        background-image: url(../assets/kmeni.png);
        position: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 102vw;
        height: 100vh;
        margin-left: -177px;
        z-index: -1;
    }

    .landing {
        /*background-image: url(../assets/road.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;*/
        height: 100%;
        width: 100%;
        position: relative;
    }

    .fss {
        text-shadow: 3px 3px 3px rgba(255, 255, 255, 0.5);
    }

    .pops {
        position: absolute;
        margin: 0 auto;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    h1 {
        font-size: 6rem;
        text-align: center;
        font-weight: bold;
        text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
    }

    h1 span {
        font-family: 'Roboto Serif', serif;
        font-weight: normal
    }

    @media (max-width: 1024px) {
        h1 {
            font-size: 5.5rem;
            text-align: left;

            font-weight: bold;
            text-shadow: 3px 3px 3px rgba(255, 255, 255, 0.5);
        }
    }

    .landingtext {
        font-size: 1.1rem;
        text-align: left;
        font-family: 'IBM Plex Sans', sans-serif !important;
        color: black
    }

    .landingtext .s2 {
        font-weight: bold;
        font-family: 'Roboto Serif', serif !important;

    }

    .linkbtn {
        font-weight: bold;
        font-family: 'IBM Plex Sans', sans-serif !important;
    }

    @media (max-width: 768px) {
        .page-back-l {
            width: 103vw;
            height: 103vh;
            margin-left: -36px;
            margin-top: -130px;
            z-index: -1;
        }

        .pops {
            width: 100%;
            top: 30vh;
        }

        .landingtext {
            font-size: 1.2rem !important;
            text-align: center;
        }

        h1 {
            font-size: 2.5rem;
        }
    }
</style>