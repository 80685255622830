<template>

    <div class="scrollview-container container is-fluid animate__animated animate__animated animate__slideInDown"
        ref="scroll-baby">

        <div class="columns is-centered is-multiline has-text-black  is-mobile is-variable is-7 is-vcentered pp">
            <div class="column is-narrow">
                <div class="hordiv"></div>
                <h1 class="medtit has-text-black is-text-shadowless has-text-left">Nabídka čisté<br>energie</h1>
            </div>
            <div class="column is-narrow">
                <b-icon icon="arrow-down" size="is-large" type="is-dark">
                </b-icon>
            </div>
            </div>
                    <div class="columns is-centered is-multiline has-text-black  is-mobile is-variable is-7 is-vcentered fh">

            <div class="column is-full has-text-centered">
                <b-icon icon="solar-panel" size="is-large" type="is-info">
                </b-icon>
            </div>
            <div class="column  is-half-tablet is-full-mobile scrollable">
                <h2>Fotovoltaika</h2>
                <div class="content">
                    Využití sluneční energie je z hlediska ochrany životního
                    prostředí nejči s tším a nejšetrnějším způsobem výroby
                    elektřiny. V současnosti je pouze minimální množství
                    spotřebované energie produkováno z obnovitelných zdrojů
                    – to se však v budoucnu nepochybně změní. Význam
                    energie z obnovitelných zdrojů se bude neustále zvyšovat
                    a nezastupitelnou roli bude zastávat i fotovoltaika.
                    <br><br>
                    <i>
                    OAK TRADE vychází ze zkušeností teamu SOLAR CENTER, který realizoval více jak 160 MWp. v solárních
parcích a 0,8MW v EWA technolgií spolu s 380kW MVE od předních 
českých výrobců.
                    </i>
                </div>
            </div>
             <div class="column is-full has-text-centered">
                <b-icon icon="lightning-bolt" size="is-large" type="is-info">
                </b-icon>
            </div>
            <div class="column  is-half-tablet is-full-mobile scrollable">
                <h3>Největší množství energie zdarma</h3>
                <div class="content">
                    Slunce produkuje bez přestání takové množství energie,
                    které by mrknutím oka dokázalo pokrýt energetické
                    potřeby celé planety. Fotovoltaické elektrárny významně
                    přispívají k ochraně klimatu a životního prostředí. Energie
                    získaná ze slunce je ekologicky čistá a nevytvář í žádný
                    nebezpečný odpad.
                </div>
            </div>
            <div class="column is-full has-text-centered">
                <b-icon icon="solar-power" size="is-large" type="is-info">
                </b-icon>
            </div>
            <div class="column  is-half-tablet is-full-mobile scrollable">
                <h3>Jak funguje sluneční elektrárna?</h3>
                <div class="content">
                    Fotovoltaika mění dopadající sluneční paprsky na elektrickou energii. Využívá přitom principu
                    fotoelektrického jevu,
                    kdy jednotlivé částice světla - fotony - dopadají na fotovoltaický článek - při dopadu se z fotonů
                    uvolňují elektrony,
                    které jsou pomocí polovodičové struktury článku přeměněny na stejnosměrný proud.

                    <br><br>
                    Sluneční záření už dnes lidstvu slouží jako užitečný zdroj energie – jeho význam neustále roste.
                    Energie ze slunce
                    patří k preferovaným zdrojům, které dokazují, že elektřina může být ekologický produkt!
                   
                </div>
<br>
            <br>
            <br>
            <br>
            <br>
            </div>
            <div class="column is-full has-text-centered">
                <b-icon icon="currency-usd" size="is-large" type="is-info">
                </b-icon>
            </div>
            <div class="column  is-half-tablet is-full-mobile scrollable">
                <h2>Investujte do FV</h2>
                <div class="content">
                    Investovat do fotovoltaiky znamená myslet na budoucnost:
na vlastní budoucnost stejně jako na budoucnost celé
planety. Výše plánované investice do instalace
fotovoltaické elektrárny závisí na mnoha okolnostech:
                  
<ol>
<li>velikosti a výkonu instalovaného systému</li>
<li>náročnosti instalace (konstrukce střechy, geologické podmínky volné plochy atd.)</li>
<li>podmínkách připojení od distributora a kapacitě elektrické sítě</li>
<li>výši garantovaných výkupních cen</li>
<li>záručním a pozáručním servisu</li>
<li>ekologickém zpracování technologií po skončení životnosti</li></ol>
<br>
<i>
Dnešní investice, je především do ekologie,
snižování CO2 stopy a zvyšování energetické samostatnosti, které lze s
pomocí HESS nebo ESS 100% dosáhnout. 
</i>

                </div>
                <br>
            <br>
            <br>
            <br>
            <br>
            </div>
            <div class="column is-full has-text-centered">
                <b-icon icon="waves" size="is-large" type="is-info">
                </b-icon>
            </div>
            <div class="column  is-half-tablet is-full-mobile scrollable">
                <h2>Vodní elektrárna</h2>

                <div class="content">

                    Energie získaná z vodních toků patří k nejdůleži tějším
obnovitelným zdrojům energie naší planety. Lidstvo vodní
energii využívá již od pradávna, tisíciletá tradice jen
umocňuje význam tohoto čistého a nevyčerpatelného
energetického zdroje. Moderní využití vodní energie je
téměř výhradně spojeno s výrobou elektřiny. První vodní
elektrárny se ve světě objevily na konci devatenáctého
století, stejně dlouhá je i tradice výstavby vodních
elektráren v Čechách a na Moravě.
                </div>
            </div>
            <div class="column is-full has-text-centered">
                <b-icon icon="water" size="is-large" type="is-info">
                </b-icon>
            </div>
            <div class="column  is-half-tablet is-full-mobile scrollable">
                <h3>Nevyčerpatelný zdroj čisté energie</h3>

                <div class="content">

Obrovská síla vodní masy dokáže vyrábět elektrickou
energii s minimálními náklady, podmínkou jsou přijatelné
náklady na výstavbu vodního díla. Vodní elektrárny jsou
často monumentální, ale vždy ekologická zařízení, která přispívají k trvalému rozvoji života o ochraně životního
prostředí na planetě Zemi. <b>Elektřina může být ekologický produkt!</b> Energie získáná z vodních toků minimálně zatěžuje
životní prostředí, vodní elektrárny jsou navíc nenáročné na obsluhu a údržbu, přečerpávací vodní elektrárny umožńují
regulovat spotřebu nebo výrobu elektrické energie podle potřeb rozvodné sítě. Jejich schopnost akumulovat energii
zvyšuje efektivnost celé elektrizační soustavy.
<br><br>
Množství využitelné energie vodního toku závisí na výškovém rozdílu dvou různých vodních hladin a na průtoku vody.
Různých úrovní vodní hladiny dosahujeme pomocí jezů nebo přehrad. Zdrojem energie je proudící voda, která se
mění pomocí turbíny a elektrického generátoru na energii elektrickou.
<i>V České republice nejsou vždy přírodní podmínky pro rozvoj vodních elektráren ideální, řada našich řek a toků nemá
dostatečný spád a množství vody. Svůj význam tak mají i menší vodní elektrárny, které mohou být v různé míře
závislé na počasí nebo ročním období. Význam vodních elektráren se zvyšuje rovnoměrně s pozorností, kterou
moderní společnost věnuje ochraně životního prostředí.</i>
                </div>
            </div>
            <br>
            <br>
            <br>
            <br>
            <br>
        </div>


    </div>
</template>

<script>
    export default {
        name: 'veShopTopbar',
        title: "Bedýnky ze dřeva",
        data() {
            return {

            }
        },
        methods: {
           
        },
        mounted() {
            this.$parent.darkmode = false;
        }


    }
</script>
<style>
    .is-text-shadowless {
        text-shadow: none;
    }

    h3 {
        font-family: "IBM Plex Sans", sans-serif;
        font-size: 2rem;
    }
</style>