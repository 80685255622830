<template>
     <div class="rt">
        <b-button tag="router-link" to="/energy-info" type="is-info" class="moreinfo animate__animated animate__zoomIn animate__delay-1s" icon-left="information" size="is-medium">Více info</b-button>
        <img class="is-hidden-mobile city animate__animated animate__slideInRight" :src="require('../assets/energyb.svg')"/>
        <a href="https://ltw-battery.com/assets/documents/catalog/44_split/en/4.pdf" target="_blank" class="is-hidden-mobile city animate__animated animate__zoomIn animate__delay-1s" ><img :src="require('../assets/energy.svg')"/></a>
        <router-link tag="img" to="/energy-info"  class="is-hidden-tablet city animate__animated animate__slideInRight" :src="require('../assets/menergyb.svg')"/>
        <router-link tag="img" to="/energy-info"  class="is-hidden-tablet city animate__animated animate__zoomIn animate__delay-1s" :src="require('../assets/menergy.png')"/>

    </div>
</template>

<script>
    export default {
        name: 'veShopTopbar',
        title: "Bedýnky ze dřeva",
        methods: {
        },
        computed: {
           
        },
        data() {
            return {
            }
        },
        mounted() {
            this.$parent.darkmode = false;
        }



    }
</script>
<style>

    @media (max-width: 768px) {
     
    }
</style>