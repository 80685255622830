<template>

  <div class="scrollview-container container is-fluid animate__animated animate__animated animate__slideInRight"
    ref="scroll-baby">

    <div class="columns is-variable is-7 is-vcentered fh m">
      <div class="column is-narrow">
        <div class="hordiv"></div>
        <h1 class="medtit has-text-centered-mobile">O nás</h1>
      </div>

      <div class="column is-hidden-mobile is-narrow">
        <b-icon icon="arrow-right" size="is-large" type="is-info">
        </b-icon>
      </div>
      <div class="column is-half-tablet is-narrow-mobile">

        <div class="content">
          <table class="infotable">

            <tbody>
              <tr>
                <td><b>Založeno</b></td>
                <td>v oboru kontinuelně od roku 2007 se základním kapitálem 10 000 000 CZK</td>
              </tr>
              <tr>
                <td colspan="2"><b>Celkový realizovaný výkon v MW</b></td>
              </tr>
              <tr>
                <td></td>
                <td>Solar PV - 185 MWp.</td>
              </tr>
              <tr>
                <td></td>
                <td>Hydro PP - 385 kW</td>
              </tr>
              <tr>
                <td></td>
                <td>Waste PP - 1,8 MW</td>
              </tr>
              <tr>
                <td><b>Zelená technologie</b></td>
                <td>EWA, STREET LIGHTS, PV SYSTEM</td>
              </tr>
              <tr>
                <td colspan="2"><b>Showrooms + Warehouse</b></td>
              </tr>
              <tr>
                <td></td>
                <td>Střešovická 35, Praha</td>
              </tr>
              <tr>
                <td></td>
                <td>Hlavní 683/104, Brno-Lelekovice</td>
              </tr>
              <tr>
                <td><b>Oblast kompetentnosti</b></td>
                <td>Domov a přidružené okolí (Argentina, Švédsko, Rakousko, Slovensko)</td>
              </tr>
              <tr>
                <td><b>Partnerství</b></td>
                <td>powered by LTW</td>
              </tr>
              <tr>
                <td><b>ISO certifikáty</b></td>
                <td>Quality Management System - ČSN EN ISO 9001:2009</td>
              </tr>
              <tr>
                <td></td>
                <td>Environmental Management System - ČSN EN ISO 14001:2005</td>
              </tr>
            </tbody>
          </table>
        </div>


      </div>


      <div class="column is-one-quarter">
        <div class="is-hidden-tablet">
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
    export default {
        name: 'veShopTopbar',
        title: "Bedýnky ze dřeva",
        data() {
            return {

            }
        }, methods: {

},
        mounted() {
            this.$parent.darkmode = true;
        }



    }
</script>
<style>
.infotable td, .infotable th
{
    border: 1px solid #839F7D55;
    padding: 0.2em 0.75em;

}
    .medtit {
        font-size: 3rem;
        font-family: 'Roboto Serif', sans-serif;
    }

    .hordiv {
        width: 50px;
        height: 10px;
        background-color: #839F7D;
    }

    h2 {
        font-size: 2rem;
        font-weight: bold;
        font-family: 'Roboto Serif', sans-serif;
    }
</style>