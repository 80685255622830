<template>

    <div class="main-page">
        <!--<div class="page-back-p animate__animated animate__zoomIn"></div>


        <div class="scrollview-container container is-fluid animate__animated animate__fadeInRight " ref="scroll-baby">

            <div class="columns is-mobile is-variable is-7 is-vcentered fh">
                <div class="column is-narrow">
                    <div class="hordiv"></div>
                    <h1 class="medtit">Partneři</h1>
                </div>

                <div class="column is-narrow">
                    <b-icon icon="arrow-right" size="is-large" type="is-info">
                    </b-icon>
                </div>
                <div class="column is-one-quarter-tablet is-half-mobile" v-for="partner in partners" :key="partner.title">
                    <div class="card-partner">
                        <div class="logo">
                            <img src="../assets/placeholder.svg"/>
                        </div>
                        <div class="name">
                    {{partner.title}}
                    </div>
                        <div class="info">
                        
                    {{partner.info}}
                    </div>
                    </div>
                </div>
                <div class="column is-one-quarter">
                </div>
            </div>


        </div>
-->
        <iframe width="100%" height="100%" class="page-back-p animate__animated animate__zoomIn is-hidden-tablet" src="https://maphub.net/embed/186797?autoplay=1" frameborder="0"></iframe>
        <div class="part is-hidden-mobile"><img src="../assets/partners.svg"></div>
    </div>
</template>

<script>
    export default {
        name: 'veShopTopbar',
        title: "Bedýnky ze dřeva",
        data() {
            return {
                partners: [{
                        title: 'Altronik s.r.o.',
                        info: 'Dukelská 11, 614 00 Brno, www.altronik.cz'
                    },

                    {
                        title: 'ELSIG - Guy Tyl',
                        info: 'Kurta Konráda 6, 190 00 Praha 9, tyl@solarcenter.cz'
                    },

                    {
                        title: 'E.T.B. Solar s.r.o.',
                        info: 'Úslavská 75, 326 00 Plzeň, www.etb-solar.cz'
                    },

                    {
                        title: 'KONSAT s.r.o.',
                        info: 'Livornská 427, 109 00 Praha 10, www.konsat.cz'
                    },

                    {
                        title: 'RATES Hodonín s.r.o.',
                        info: 'Havlíčkova 2, 695 01 Hodonín, www.rates.cz '
                    },

                    {
                        title: 'SATTURN Holešov s.r.o.',
                        info: 'Dlažánky 305, 769 01 Holešov, www.satturn.cz'
                    },
                    {
                        title: 'SOLARHOME s.r.o.',
                        info: 'Široká 241, 251 01 Říčany u Prahy, www.solarhome.cz'
                    },
                    {
                        title: 'TERSYS s.r.o.',
                        info: 'Sokolská 354, 517 71 České Meziříčí, www.tersys.cz'
                    },
                ]
            }
        }, methods: {
  scrollX(e) {
        if(e.wheelDelta < -100 || e.wheelDelta > 100) {

        this.$refs['scroll-baby'].scrollBy({
          left: e.deltaY
        });
        }
      },
},
        mounted() {
            this.$parent.darkmode = false;
        }



    }
</script>
<style>
    .page-back-p {
        
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100vw;
        height: 100vh;
        margin-left: -147px;
        z-index: -1;
    }
    .part
    {
        padding: 25px;
    }
    .card-partner
    {
        border: 1px solid white;
        height: 400px;
        border-radius: 20px;
        text-align: center;
        padding: 15px;
    }
    .card-partner .name {
        font-weight: bold;
        font-size: 2rem;
        font-family: 'Roboto Serif', sans-serif;
    }
    .card-partner .logo {
        margin-top: 30px;
        margin-bottom: 30px;
       
    }
    .logo img {
         height: 150px;
    }
     @media (max-width: 768px) {
       .page-back-p {
              width: 103vw;
    height: 103vh;
    margin-left: -9px;
    margin-top: -130px;
          z-index: -1;
        }
   
   }
</style>