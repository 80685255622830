import Vue from 'vue'
import App from './App.vue'
import 'animate.css';
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
Vue.use(Buefy)
import './assets/custom.scss'
import Vuebar from 'vuebar';
Vue.use(Vuebar);

import VueCookies from 'vue-cookies';
Vue.use(VueCookies);
Vue.config.productionTip = false
import VueRouter from 'vue-router'
import router from './router'
Vue.use(VueRouter)
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
