<template>
    <div class="landindg container is-fluid">

        <p class="has-text-centered" style="position: absolute; bottom: 40px;">
        <img src="../assets/walk.gif"/></p>
        <div class="columns popsa is-variable is-1 is-centered is-mobile">
            <div class="column is-narrow">

                <h1 class="mintit">
                    Předmět stránky, kterou požadujete

                </h1>
            </div>
            <div class="column is-narrow">
                <h1 class="mintit"><span>jsme zatím nezrealizovali</span></h1>
            </div>
        </div>
        <p class="has-text-centered">
    
        Můžete nás ale kontaktovat, rádi ho zrealizujeme</p>
       
    </div>
</template>

<script>
    export default {
        name: 'veShopTopbar',
        title: "Bedýnky ze dřeva",
        data() {
            return {

            }
        },
        mounted() {
            this.$parent.darkmode = true;
        }


    }
</script>
<style>
    .popsa {
        padding-top: calc(50vh - 5rem);
    }

    .mintit {
        font-size: 2rem;
        text-align: center;

        font-weight: bold;
        text-shadow: 3px 3px 3px rgba(0, 0, 0.5);
    }

    h1 span {
        font-family: 'Roboto Serif', serif;
        font-weight: normal
    }

    .landingtext {
        font-size: 1.1rem;
        text-align: left;
        font-family: 'Roboto', sans-serif;

    }

    .landingtext .s2 {
        font-weight: bold;
        font-family: 'Roboto Serif', serif;

    }

    .linkbtn {
        font-weight: bold;
        font-family: 'Roboto', serif;
    }
</style>