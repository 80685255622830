<template>

    <div class="fh">
        <div class="page-back-r animate__animated animate__zoomIn"></div>


        <div class="scrollview-container  animate__animated animate__fadeInDown">

            <div class="columns is-centered is-multiline is-variable is-7 is-vcentered pp">
                <div class="column pdlel has-text-centered-mobile has-text-black is-narrow">
                    <div class="hordiv"></div>
                    <h1 class="medtit has-text-black is-text-shadowless has-text-left has-text-centered-mobile ">
                        Reference</h1>
                    <div class="box bx">
                        <ul>
                            <li><b>Česko</b> - 185 MWp, 6,8 MWh + 0,15 MWh <b-tag type="is-info">HESS</b-tag>
                            </li>
                            <li><b>Německo</b> - 50 MWp</li>
                            <li><b>Itálie</b> - 5 MWp</li>
                            <li><b>Rakousko</b> - EWA 0.6 MW, 3,9 MWh</li>
                            <li><b>Niger</b> - 4.8 MWp</li>
                            <li><b>Argentina</b> - 98 kWp</li>
                            <li><b>Švédsko</b> - 3.2 MWp, 140 kWh</li>
                        </ul>
                    </div>
                </div>
                <div class="column is-hidden-mobile is-narrow">
                    <b-icon icon="arrow-down" size="is-large" type="is-info">
                    </b-icon>
                </div>
            </div>
            <div class="columns is-multiline">
                <div class="column is-one-third" v-for="item in news.items" :key="item.title">
                    <div class="media-hero" v-bind:style="{ backgroundImage: 'url(' + item.image + ')' }">
                        <div class="hero-ribbon">
                            <p class="hero-meta">{{item.date}}</p>
                            <p class="minihero-title">{{item.title}}</p>
                        </div>
                    </div>
                </div>

            </div>


        </div>
    </div>
</template>

<script>
    export default {
        name: 'veShopTopbar',
        title: "Bedýnky ze dřeva",
        methods: {
            scrollX(e) {
                if (window.innerWidth > 768) {
                    if (e.wheelDelta < -100 || e.wheelDelta > 100) {

                        this.$refs['scroll-baby'].scrollBy({
                            left: e.deltaY
                        });
                    }
                }
               
            },
        },
        computed: {
            newsc: function () {
                var ret = JSON.parse(JSON.stringify(this.news.items));
                ret.shift();
                return ret
            }
        },
        data() {
            return {
                news: {
                    items: [{
                            title: "FVE Žabčice",
                            date: "5,6 MWp",
                            text: "sfojsdijfisdfji",
                            image: require("../assets/fve-zabcice-2.jpg")
                        },
                        {
                            title: "FVE Miroslav",
                            date: "954 kWp",
                            text: "sfojsdijfisdfji",
                            image: require("../assets/miroslav.jpg")
                        },
                        {
                            title: "FVE Roudnice nad Labem",
                            date: "67 kWp",
                            text: "sfojsdijfisdfji",
                            image: require("../assets/roudnice.jpg")
                        }, {
                            title: "Instalace smart-grid produktů RUG od LTW",
                            date: "až 151,4 kW",
                            image: "https://ltw-battery.com/assets/img/Obrázek1.png"
                        }, {
                            title: "FVE Dolní Žandov",
                            date: "285 kWp",
                            text: "sfojsdijfisdfji",
                            image: require("../assets/žandov.jpg")
                        }, {
                            title: "FVE Chýnov u Tábora",
                            date: "2 kWp",
                            text: "sfojsdijfisdfji",
                            image: require("../assets/chynov.jpg")
                        }, {
                            title: "FVE Tachov I.",
                            date: "2,2 MWp",
                            text: "sfojsdijfisdfji",
                            image: require("../assets/tachovi.png")
                        }, {
                            title: "FVE Tachov III.",
                            date: "3,8 MWp",
                            text: "sfojsdijfisdfji",
                            image: require("../assets/tachoviii.png")
                        }, {
                            title: "FVE Hořovice",
                            date: "970 kWp",
                            text: "sfojsdijfisdfji",
                            image: require("../assets/horovice.jpg")
                        }
                    ]
                }
            }
        },
        mounted() {
            this.$parent.darkmode = false;
        }



    }
</script>
<style>





    .page-back-r {
        background-image: url(../assets/refrs.jpg);
        position: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100vw;
        height: 100vh;
        margin-left: -142px;
        z-index: -1;
    }


    html {
        scrollbar-color: #ffffff22 transparent;
        overflow-x: hidden;
    }

    .pdlel {
        margin-left: 32px !important;
    }

    .media-hero {
        position: relative;
        height: 200px;
        margin: auto;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        min-width: 100%;
        cursor: pointer;
    }

    .hero-ribbon {
        position: absolute;
        transition: all 0.5s ease;
        background-color: rgba(0, 0, 0, 0.7);
        width: 100%;
        bottom: 0;
        padding: 20px;
        min-height: 100px;
    }

    .media-hero:hover .hero-ribbon {
        min-height: 50%;
        transition: all 0.5s ease;

    }

    .hero-title {
        font-size: 3rem;
        font-weight: bold;
    }

    ::-webkit-scrollbar {
        height: 4px;
        width: 4px;
        background: transparent;
    }

    ::-webkit-scrollbar-thumb:horizontal {
        background: #ffffff22;
        border-radius: 10px;
    }

    .minihero-title {
        font-size: 2rem;
        font-weight: bold;
    }

    @supports (-webkit-text-stroke: 1px black) {

        .hero-title,
        .minihero-title {
            color: transparent;
            -webkit-text-stroke: 1px white;
        }
    }

    .hero-meta {

        font-family: 'Roboto Serif', sans-serif;
    }

    .sfh {
        height: calc(100% - (var(--columnGap) * 2));
    }

    .mt {
        margin-top: 0;
    }

    .vc {
        flex-direction: column;
    }

    .vck {
        height: 50%;
    }

    .scolumns {
        display: flex;
        flex-direction: column;
        flex: 2;
        height: calc(100% + calc(var(--columnGap) * 2));
        flex-wrap: wrap;
        width: 100%;
        margin: calc(-1 * var(--columnGap));

    }

    .scolumn {
        width: 500px;
        padding: var(--columnGap);
    }

    .spacer-col {
        width: 500px;
        padding: var(--columnGap);

    }

    @media (max-width: 768px) {
        .scrollview-container {
            overflow-x: hidden !important;
            width: 100vw !important;
            height: calc(100vh + 150px)!important;
            overflow-y: scroll !important;
        }
html
{
    overflow-y:hidden;
}
        .has-text-centered-mobile {
            text-align: center;
        }
.box {
    border-radius: 0px;
}
        .hordiv {
            margin-left: calc(50% - 25px);
        }

        .pdlel {
            margin-left: 0px !important;
        }
        .bx {
            margin-left:var(--columnGap);margin-right: var(--columnGap);
        }
        .page-back-r {
            width: 103vw;
            height: 103vh;
            margin-left: 0px;
            margin-top: -130px;
            z-index: -1;
        }

        .mn {
            height: 100%;
        }

.scolumns {
    display: flex;
    flex-direction: row;
    flex: 2;
    height: calc(100% + calc(var(--columnGap) * 2));
    flex-wrap: wrap;
    width: 100%;
    margin:0;

}
.ppp 
{
    overflow:scroll;
}
.sfh {
    height: 100%!important;
}
.scolumn {
    width: 100%;height: 400px;
    padding: var(--columnGap);
}
        .mt {
            margin-top: 0;
        }
    }
</style>