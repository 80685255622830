<template>

    <div class="scrollview-container container is-fluid animate__animated animate__animated animate__slideInRight"  ref="scroll-baby">

        <div class="columns  is-variable is-7 is-vcentered fh m">
            <div class="column is-narrow">
                <div class="hordiv"></div>
                <h1 class="medtit has-text-centered-mobile">Team</h1>
            </div>
            <div class="column is-hidden-mobile is-narrow">
                <b-icon icon="family-tree" size="is-large" type="is-info">
                </b-icon>
            </div>
             <div class="column is-hidden-mobile is-half-tablet is-full-mobile fmt">
                <img src="../assets/tree.svg"/>
            </div><div class="column is-hidden-tablet is-half-tablet is-full-mobile ">
                <img src="../assets/tree_m.svg"/>
            </div>
            
            <div class="column is-one-quarter">
            </div>
        </div>


    </div>
</template>

<script>
    export default {
        name: 'veShopTopbar',
        title: "Bedýnky ze dřeva",
        data() {
            return {

            }
        }, methods: {

},
        mounted() {
            this.$parent.darkmode = true;
        }



    }
</script>
<style>
.infotable td, .infotable th
{
    border: 1px solid #839F7D55;
    padding: 0.2em 0.75em;

}
    .medtit {
        font-size: 3rem;
        font-family: 'Roboto Serif', sans-serif;
    }

    .hordiv {
        width: 50px;
        height: 10px;
        background-color: #839F7D;
    }
    @media (max-width: 768px) {
        .fmt {
            width: 90vh!important;
        }
    }

    h2 {
        font-size: 2rem;
        font-weight: bold;
        font-family: 'Roboto Serif', sans-serif;
    }
</style>