<template>

    <div class="scrollview-container container is-fluid animate__animated animate__animated animate__slideInRight"
        ref="scroll-baby">

        <div class="columns is-multiline is-centered is-variable is-7 is-vcentered pp">
            <div class="column is-narrow">
                <div class="hordiv"></div>
                <h1 class="medtit has-text-centered-mobile">Etický kodex</h1>
            </div>
            </div>


        <div class="columns is-multiline is-centered is-variable is-7 is-vcentered pp">

            <div class="column is-full has-text-centered">
                <b-icon icon="information-variant" size="is-large" type="is-info">
                </b-icon>
            </div>
            <div class="column  is-half-tablet has-text-centered-mobile is-full-mobile">
                <h2 class="is-hidden-mobile">Etický kodex společnosti OAK TRADE</h2>
                <br>
                <div class="content">
                    Společnost OAK TRADE působí na českém trhu řadu let, od počátku svojí podnikatelské činnosti se
                    snaží přispívat k rozvoji ekologických technologií a jejich využití.
                    <br><br>
                    Etický kodex společnosti OAK TRADE akceptuje kromě ekologické odpovědnosti i důraz na elementární
                    podnikatelskou a společenskou etiku. Hlavními rysy naší práce jsou proto slušnost, vstřícnost a
                    týmový duch.
                </div>


            </div>
            <div class="column is-full has-text-centered">
                <b-icon icon="format-list-numbered" size="is-large" type="is-info">
                </b-icon>
            </div>
            <div class="column  is-half-tablet is-full-mobile">
                <ol>
                    <li>Náš cíl - spokojený zákazník.</li>
                    <li>Prostředek - kvalita a rozsah služeb.
                    </li>
                    <li>Cíle lze dosáhout jen partnerskou prací v týmu.
                    </li>
                    <li>Ve firmě nejsou důležitější a méně významná pracovní místa! Při týmové práci se každý z nás
                        snaží odvádět maximálně profesionální výkon.
                    </li>
                    <li>Chceme pracovat v atmosféře, ve které se cítíme dobře. Proto jednáme otevřeně, čestně a
                        zdvořile.
                    </li>
                    <li>S kolegy jednáme tak, jak bychom si přáli, aby oni jednali s námi.
                    </li>
                    <li>Splnění daných slibů považujeme za věc osobní cti.
                    </li>
                    <li>Po celou dobu je nutno dbát vytyčených cílů a usilovat o jejich dosažení včetně realizace!
                    </li>
                    <li>Chceme pracovat v dobré firmě.
                    </li>
                    <li>Dobře víme, že o udržení dobrého jména firmy se musí snažit každý z nás.
                    </li>
                    <li>Dobré jméno se získává obtížně, ale ztrácí se velmi snadno!
                    </li>
                </ol>
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
            </div>
        </div>


    </div>
</template>

<script>
    export default {
        name: 'veShopTopbar',
        title: "Bedýnky ze dřeva",
        data() {
            return {

            }
        }, methods: {
  scrollX(e) {
        if(e.wheelDelta < -100 || e.wheelDelta > 100) {

        this.$refs['scroll-baby'].scrollBy({
          left: e.deltaY
        });
        }
      },
},
        mounted() {
            this.$parent.darkmode = true;
        }



    }
</script>
<style>
.infotable td, .infotable th
{
    border: 1px solid #839F7D55;
    padding: 0.2em 0.75em;

}

    .medtit {
        font-size: 3rem;
        font-family: 'Roboto Serif', sans-serif;
    }

    .hordiv {
        width: 50px;
        height: 10px;
        background-color: #839F7D;
    }

    h2 {
        font-size: 2rem;
        font-weight: bold;
        font-family: 'Roboto Serif', sans-serif;
    }
</style>