<template>
  <div id="app" class="fh">
    <div class=" fh">
      <div class="preloader" v-bind:class="{ 'loaded': loaded }">
        <div class="preloaderlower animate__animated animate__fadeIn" v-bind:class="{ 'animate__fadeOut': loaded2 }">
        </div>
        <div class="preloaderupper animate__animated animate__fadeIn" v-bind:class="{ 'animate__fadeOut': loaded2 }">
        </div>
      </div>
      <div class="columns is-gapless is-mobile fh map frontpage">
        <div class="column is-narrow fh has-text-left sb" v-bind:class="{ 'opened': !menustate }" id="sidebar">
          <div class="menuside columns is-mobile is-gapless">
            <div class="column is-narrow cb">
              <div class="columns is-mobile is-vcentered">
                <div class="column is-narrow" @click="menuopen">
                  <b-icon icon="menu" size="is-large" v-bind:type="{ 'is-black': !darkmode, 'is-white': darkmode }"
                    v-if="menustate">
                  </b-icon>
                  <b-icon icon="close" size="is-large" class=" is-hidden-mobile"
                    v-bind:type="{ 'is-black': !darkmode, 'is-white': darkmode }" v-if="!menustate">
                  </b-icon>
                </div>
                <transition name="fade">
                  <div class="column is-hidden-mobile is-narrow clicker" v-if="!menustate_alt">
                    <img src="./assets/vertical_o_n.svg" v-if="darkmode" class="clicker" />
                    <img src="./assets/vertical_o.svg" v-if="!darkmode" class="clicker" />
                  </div>
                </transition>
              </div>

            </div>


            <div class="column" v-if="menustate"></div>

            <div class="column" v-if="!menustate">
              <div class="columns menuitself fh is-mobile">
                <div class="column is-narrow is-hidden-tablet" @click="menuopen">
                  <b-icon icon="close" size="is-large" v-bind:type="{ 'is-black': !darkmode, 'is-white': darkmode }"
                    v-if="!menustate">
                  </b-icon>
                </div>
                <div class="column is-narrow is-hidden-mobile" v-if="!menustate_alt">
                  <div class="hdivider" v-bind:class="{ 'has-background-black': !darkmode }"></div>

                </div>
                <div class="column"></div>
                <div class="column" id="menu" v-if="!menustate_alt">


                  <ul class="menulist">
                    <li class="menulink animate__animated animate__fadeInLeft"
                      :style="'animation-delay: 0.' + item.delay + 's; animation-duration: 0.5s;'" v-for="item in menu"
                      v-bind:key="item.title"  
                      @mouseleave="item.expanded = false">
                      <div class="columns is-mobile is-gapless">
                        <div class="column is-narrow"  @mouseover="item.expanded = true" @click="menuopen();item.expanded = false">
                          <router-link tag="a" :to="item.link" v-bind:class="{ 'has-text-black': !darkmode }">
                            <div class="linkblock"></div> {{item.title}}
                          </router-link>
                        </div>
                        <div class="column ml is-narrow" @click="item.expanded = true" v-for="subitem in item.children" v-bind:key="subitem.title">
                          <b-icon :icon="subitem.icon" size="is-small" type="is-info">
                          </b-icon>
                        </div>
                      </div>

                      <ul class="submenulist" v-bind:class="{ 'expanded': item.expanded }" v-if="item.children">
                        <li class="submenulink" v-for="subitem in item.children" v-bind:key="subitem.title"
                          @click="menuopen();item.expanded = false">
                          <router-link tag="a" :to="subitem.link" v-bind:class="{ 'has-text-black': !darkmode }">
                            <b-icon :icon="subitem.icon" size="is-small">
                            </b-icon>&nbsp;&nbsp;&nbsp;&nbsp;{{subitem.title}}
                          </router-link>
                        </li>
                      </ul>
                    </li>

                  </ul>

                </div>
                <div class="column"></div>

                <transition name="fade">
                  <div class="column is-narrow" id="contactmenu" v-if="!menustate_alt">
                    <p class="c1" v-bind:class="{ 'has-text-black': !darkmode }">Od pevné spolupráce</p>
                    <p class="c2" v-bind:class="{ 'has-text-black': !darkmode }">vás dělí jeden telefonát</p>
                    <p class="number-phone" v-bind:class="{ 'has-text-black': !darkmode }">{{number}}</p>
                    <b-button type="is-primary" class="dtz" icon-left="email" tag="router-link"
                to="/dotaz">Dotaz?</b-button>
                  </div>
                </transition>
              </div>
            </div>
            <transition name="fade">
              <div class="column is-narrow cb" v-if="menustate">
                <router-link tag="div" to="/">
                  <img v-if="darkmode" src="./assets/icon_o_n.svg" class="menulogo" />
                  <img v-if="!darkmode" src="./assets/icon_o.svg" class="menulogo" />

                </router-link>
              </div>
            </transition>
            <div class="column" v-if="menustate_alt"></div>

            <transition name="fade">
              <div class="column is-narrow cb" v-if="menustate">
                <router-link tag="div" to="/kontakt">
                  <b-icon icon="phone" size="is-large" v-bind:type="{ 'is-black': !darkmode, 'is-white': darkmode }">
                  </b-icon>
                </router-link>
                
              </div>
            </transition>
          </div>
        </div>

        <div class="column is-narrow is-hidden-mobile" id="divider">
          <transition name="fade">
            <div class="divider" v-bind:class="{ 'light': !darkmode }" v-if="menustate"></div>
            <div class="divider hidden" v-else></div>

          </transition>
        </div>
        <div class="column rel mn" v-bind:class="{ 'collapse': !menustate }" id="main">
          <transition :name="transitionName" mode="out-in">
            <router-view />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'
  export default {
    name: 'OakTrade',
    components: {

    },
    data() {
      return {
        menustate: true,
        darkmode: true,
        root: null,
        loaded: false,
        loaded2: false,
        menustate_alt: true,
        sidebaropen: "",
        transitionName: "fade",
        number: "778 979 188",
        menu: [{
            title: "Ekologie",
            link: "/ekologie",
            delay: 0,
            expanded: false,
            children: [{
                title: "Čistá města",
                link: "/clean-city",
                icon: "city-variant"
              }, {
                title: "Čistá energie",
                link: "/clean-energy",
                icon: "solar-power"
              },
              {
                title: "Osvětlení bez přípojky",
                link: "/wireless-lighting",
                icon: "coach-lamp"
              }, {
                title: "Bezemisní spalování odpadků",
                link: "/waste",
                icon: "delete-empty"
              }/*, {
                title: "Elektromobilita",
                link: "/e-mobility",
                icon: "car-electric"
              }*/
            ]
          },
           {
            title: "O nás",
            link: "/o-nas",
            delay: 1,
            expanded: false,
            children: [{
                title: "Firma",
                link: "/firma",
                icon: "information-variant"
              }, {
                title: "Náš tým",
                link: "/team",
                icon: "account-group"
              },
              {
                title: "...který se řídí etickým kodexem",
                link: "/eticky-kodex",
                icon: "format-list-numbered"
              }
            ]
          
          },
           {
            title: "Reference",
            link: "/reference",
            delay: 2,
            expanded: false
          },
          {
            title: "Partneři",
            link: "/partneri",
            delay: 3,
            expanded: false
          },
         
          {
            title: "Kontakt",
            link: "/kontakt",
            delay: 4,
            expanded: false
          },
         
        ]
      }
    },
    methods: {
      menuopen: function () {
        if (this.menustate) {
          this.menustate = !this.menustate;
          setTimeout(() => this.menustate_alt = !this.menustate_alt, 300);
        } else {
          this.menustate_alt = !this.menustate_alt;
          setTimeout(() => this.menustate = !this.menustate, 300);
        }

        if (!this.menustate) {
          this.sidebaropen = "opened";
        } else {
          this.sidebaropen = "closed";
        }
      },
      exp: _.debounce((item) => {
        item.expanded = true
      }, 300)
    },
    mounted: function () {
     
      this.root = document.documentElement;
      document.querySelector('meta[name="theme-color"]').setAttribute("content", "#080f08");

      setTimeout(() => {this.loaded = true;document.querySelector('meta[name="theme-color"]').setAttribute("content", "#E2E9E2");}, 1500);
      setTimeout(() => this.loaded2 = true, 1000);
    },
    watch: {
      darkmode: {
        handler: function () {
          this.$nextTick(() => {
            if (this.darkmode) {
              this.root.style.setProperty("--siteback", "#080f08");
              document.querySelector('meta[name="theme-color"]').setAttribute("content", "#080f08");

            } else {
              this.root.style.setProperty("--siteback", "#E2E9E2");
              document.querySelector('meta[name="theme-color"]').setAttribute("content", "#E2E9E2");

            }
          })
        },
        immediate: true

      }
    }

  }
</script>
<style>
  @import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:wght@400;500;600;700&family=IBM+Plex+Sans:wght@300;400;500;700&display=swap');
body{
    position: fixed;
}
  html,
  body {

    height: 100%;
    width: 100%;
    overflow: hidden;
    font-family: 'IBM Plex Sans', sans-serif !important;
    color: white !important
  }

  .ml {
    margin-left: 1rem !important;
  }

  .animate__animated {
    animation-timing-function: cubic-bezier(0.230, 1.000, 0.320, 1.000);
  }

  .preloader {
    height: 100vh;
    background-image: url(./assets/preloader.jpg);
    transition: height 1.8s cubic-bezier(0.230, 1.000, 0.320, 1.000);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .preloaderlower {
    background-image: url(./assets/preloaderbottom.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    height: 100vh;
    width: 100vw;
  }

  .preloaderupper {
    background-image: url(./assets/oak.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    animation-delay: 0.2s;
    height: 100vh;
    width: 100vw;
  }

  .loaded {
    height: 0px !important;
  }

  .submenulist {
    font-size: 1rem;
    font-family: 'IBM Plex Sans', sans-serif !important;
    font-weight: 900;
    margin-top: -25px;
    margin-left: 1rem;
    transition: all 0.2s ease;
    max-height:0px;
    opacity: 0;
  }
  .dtz {
        padding-top: 0;
    padding-bottom: 0;
    width: 188px;
    border-radius: 0!important;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
  }
 .submenulist.expanded {
    opacity: 1;
    max-height:116px;
  }

  .mn.collapse {
    opacity: 0.3
  }

  .mn {
    transition: opacity 0.4s;

  }

  html {
    background-color: var(--siteback) !important;
    transition: background-color 0.4s;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .1s;
  }

  .fade-enter,
  .fade-leave-to

  /* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
  }

  .insert-enter-active,
  .insert-leave-active {
    transition: all .1s;
  }

  .insert-enter,
  .insert-leave-to

  /* .fade-leave-active below version 2.1.8 */
    {
    max-height: 0px;
  }

  .menuside {
    padding: 35px;
    padding-left: 35px;
    height: 100%;
    flex-direction: column;
  }

  .menuitself {
    flex-direction: column;
  }

  .fh,
  .app {
    height: 100%;
  }

  .rel {
    position: relative;
  }

  .menulogo {
    width: 48px
  }

  .clicker {
    width: auto;
    height: 37px;
    opacity: 0.9;
    margin-top: -9px;
  }

  .divider {
    height: calc(100% - 50px);
    margin: 25px;
    margin-left: 00px;
    width: 1px;
    z-index: 50000;
    background-color: white
  }

  .divider.hidden {
    background-color: transparent
  }

  .menulist {
    margin: -7px;
    font-size: 2rem;
    font-family: 'Roboto Serif', serif;
  }

  .menulink a {
    color: white;
    font-weight: 400;
    transition: all 0.1s ease;

    display: flex;
    align-items: center;
  }

  .linkblock {
    height: 1rem;
    margin-right: 15px;
    width: 0px;
    margin-bottom: 7px;
    transition: all 0.1s ease;
    background-color: #839F7D;
  }

  .menulink {
    margin-top: 20px;
  }

  .menulink a:hover {
    font-weight: 700;
    color: white;
    transition: all 0.1s ease;
  }

  .number-phone {
    font-size: 2rem;
    font-weight: 700;
  }

  .c1 {
    letter-spacing: 1.8px;
    margin-bottom: -3px;
    opacity: 0.7;
    font-weight: 300;

  }

  .c2 {
    letter-spacing: 0.8px;
    margin-bottom: -7px;
    opacity: 0.7;
    font-weight: 300;
  }

  .menulink a:hover .linkblock {

    width: 10px;
  }

  .hdivider {
    height: 1px;
    margin: 10px;
    margin-top: 15px;
    width: 250px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .divider.light {
    background-color: black;
  }

  .sb {
    min-width: 118px;
    z-index: 20;
    background: transparent;
    transition: all 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940);
  }

  .sb.opened {
    min-width: 80% !important;
    z-index: 6;
    background: #080f08;
    background: linear-gradient(90deg, var(--siteback) 80%, rgba(0, 0, 0, 0) 100%);
  }

  .cb {
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-decoration: none;
  }

  @media (max-width: 768px) {

    .map,
    .menuitself {
      flex-direction: column;
    }

    .menuside {
      flex-direction: row;
      height: auto !important;
    }

    .fh {
      height: auto !important;

    }

    .fh.m {
      height: 70% !important;
    }

    .scrollable {
      overflow-y: scroll;
      height: calc(100vh - 200px);
    }

    .sb.opened {
      min-width: 100% !important;
      background: var(--siteback);
      height: 100vh !important;
    }
  }
</style>