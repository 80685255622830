<template>

    <div class="scrollview-container pdd  animate__animated animate__animated animate__slideInDown">

        <section class="bp__body">
            <div class="bp__topimage" :style="{ 'background-image': 'url(' + require('../assets/forest.jpg') + ')'}">
            </div>
            <div class="columns is-vcentered">
                <div class="column is-narrow">
                    <div class="hordiv"></div>
                    <h1 class="medtit has-text-centered-mobile">Ekologie</h1>
                </div>
                <div class="column is-hidden-mobile"></div>
                <div class="column is-narrow is-hidden-mobile">
                    <b-icon icon="arrow-down" size="is-large" type="is-info">
                    </b-icon>
                </div>

                <div class="column is-hidden-mobile"></div>
                <div class="column has-text-right is-narrow is-hidden-mobile">
                    <b>Naposledy upraveno</b>
                    <p>7. srpen 2022</p>
                </div>
                <div class="column is-narrow is-hidden-mobile">
                    <b-icon icon="clock" size="is-medium" type="is-info">
                    </b-icon>
                </div>
            </div>
            <div class="bp__body">
                <div class="content">
                    Lidská společnost se rozrůstá a spolu s ní rostou i její potřeby. Energetická náročnost moderní
                    civilizace je obrovská, přitom zdroje tradičních energií jsou velmi omezené a z velké části již
                    vyčerpané. Jedinou smyslupnou cestou k přijatelné budoucnosti je tak využití obnovitelných
                    energetických zdrojů – těch nám naštěstí příroda poskytuje hned několik. Ekologické chování je tak
                    zároveň odpovědné i ekonomicky výhodné.

                </div>
                <div class="buttons is-centered">
                    <b-button v-for="child in children" :key="child.link" :icon-left="child.icon" tag="router-link" :to="child.link" type="is-light is-outlined">
                        {{child.title}}
                    </b-button>
                </div>
                <div class="content">
                    Hlavní ekologické argumenty jsou dva: využívání čistých (obnovitelných) energetických zdrojů, jako
                    jsou větrné, vodní a fotovoltaické elektrárny a snižování energetické spotřeby. Energetickou
                    spotřebu lidstva nelze snižovat do nekonečna, úspěšně lze však snižovat množství energie produkované
                    neekologickými technologiemi. Slunce, voda a vítr poskytují dostatek energie, jen se ji musíme
                    naučit důsledně využívat. Společnost OAK TRADE vsadila na cestu do budoucnosti, společnost OAK TRADE
                    vsadila na ekologii.
                </div>
            </div>
        </section>



    </div>
</template>

<script>
    export default {
        name: 'veShopTopbar',
        title: "Bedýnky ze dřeva",
        data() {
            return {
                children: [{
                    title: "Čistá města",
                    link: "/clean-city",
                    icon: "city-variant"
                }, {
                    title: "Čistá energie",
                    link: "/clean-energy",
                    icon: "solar-power"
                },
                {
                    title: "Osvětlení bez přípojky",
                    link: "/wireless-lighting",
                    icon: "coach-lamp"
                }, {
                    title: "Bezemisní spalování odpadků",
                    link: "/waste",
                    icon: "delete-empty"
                }/*, {
                title: "Elektromobilita",
                link: "/e-mobility",
                icon: "car-electric"
              }*/
                ]
            }
        }, methods: {

},
       mounted() {
            this.$parent.darkmode = true;
        }


    }
</script>
<style>






.bp__body
{
    padding-right: 25px;
    padding-top: 26px;
}
.bp__topimage
{
    height: 300px;
    width: 100%;
    background-position: center;
    background-size: cover;
    margin-bottom: 25px;
}



.pp {
    height:50vh;
}  
.scrollview-container
{
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}
@media (max-width: 768px) {
    .scrollview-container {
            padding-bottom: 200px;
        }
    .pdd {
        height: 90vh !important;
    }
    .hh
    {
        height:700px;
    }
        .pp {
            height: auto;
        }
        .bp__body {
            padding: 5px;
            padding-top: 26px;
            text-align: center;
            margin-bottom: 50px;
        }
    
        .bp__topimage {
            height: 300px;
            width: 100%;
            background-position: center;
            background-size: cover;
            margin-bottom: 25px;
        }
}
.medtit
{
    font-size: 3rem;
    font-family: 'Roboto Serif', sans-serif;
}
.hordiv
{
    width: 50px;
    height:10px;
    background-color: #839F7D;
}
h2
{
    font-size: 1.8rem;
    font-weight: bold;
    font-family: 'Roboto Serif', sans-serif;
}

</style>