<template>
    <div class="main-page rl container is-fluid  scrollable h100">
        <div class="columns fh is-vcentered">
            <div class="column is-one-quarter is-hidden-mobile"> <iframe width="100%" class="contact-map"
                    src="https://maphub.net/embed/189968?autoplay=1" frameborder="0"></iframe></div>
            <div class="column">

                <h2 class="contact-title">
                    Zeptejte se <span>našeho týmu</span>
                </h2>
                <div class="contact-people">
                    <img src="../assets/contactpeople.svg">
                </div>
                <div id="contact-form" class="contact-form">

                    <input required name="name"
                        class="input is-info is-radiusless has-background-dark has-text-light ta" v-model='contact.name'
                        placeholder="Jméno" type="text" autocomplete="nasrat"><br>
                    <input required name="email"
                        class="input is-info is-radiusless has-background-dark has-text-light ta"
                        v-model="contact.email" placeholder="E-mail" type="email" autocomplete="nasrat"><br>
                    <textarea name="message"
                        class="textarea  is-info is-radiusless has-background-dark has-text-light ta"
                        v-model="contact.message" rows="4" placeholder="Zpráva"></textarea>
                    <div class="columns is-mobile">
                        <div class="column is-narrow aad">15+50 = </div>
                        <div class="column is-narrow-tablet"><input required name="email"
                                class="input is-info is-radiusless has-background-dark has-text-light ta"
                                v-model="contact.captcha" placeholder="" type="text"><br></div>
                        <div class="column is-narrow is-hidden-mobile"><button @click="send_form"
                                class="button is-info is-radiusless">Odeslat</button></div>
                    </div>
                    <button @click="send_form"
                        class="button is-info is-hidden-tablet btnmaxwidth is-radiusless">Odeslat</button>
                </div>
                <div class="columns is-centered is-marginless pc">
                    <div class="column is-narrow is-paddingless has-text-centered-mobile ">
                        <div class="columns is-mobile is-centered is-vcentered is-marginless pc">
                            <div class="column is-narrow">
                                <b-icon icon="phone" size="large" type="is-info"></b-icon>
                            </div>
                            <div class="column">
                                <p class="number-c">778 979 188</p>
                            </div>
                        </div>
                        <div class="columns is-mobile is-centered is-vcentered is-marginless pc">
                            <div class="column is-narrow">
                                <b-icon icon="mail" size="large" type="is-info"></b-icon>
                            </div>
                            <div class="column">
                                <p class="number-c">info@oak-trade.cz</p>
                            </div>
                        </div>
                    </div>
                    <div class="column is-narrow is-hidden-mobile">
                        <b-icon icon="information-variant" size="medium"></b-icon>
                    </div>
                    <div class="column is-one-quarter has-text-centered-mobile">
                        <p class="number-2">OAK trade s.r.o.</p>
                        <p class="number-3"><B>IČO</B> 07023014</p>
                        <p class="number-3"><b>DIČ</b> CZ07023014</p>
                        <small class="clr">Společnost s ručením omezeným zapsána u Městského soudu v Praze se spisovou
                            značkou 321942 C</small>
                    </div>



                </div>
            </div>
            <div class="column is-one-quarter is-hidden-tablet"> <iframe width="100%" class="contact-map"
                    src="https://maphub.net/embed/189968?autoplay=1" frameborder="0"></iframe></div>
        </div>
    </div>
</template>


<script>
    import {
        Base64
    } from 'js-base64';
    import axios from "axios";
    export default {
        data() {
            return {
                contact: {
                    name: "",
                    email: "",
                    message: "",
                    captcha: ""
                },
                control: ""
            }
        },
        mounted() {
            this.$parent.darkmode = true;
        },
        methods: {
            send_form: function () {
                if (this.contact.name != "" && this.contact.email != "" && this.contact.message != "" && this
                    .contact.captcha != "") {
                    var data = new FormData();
                    data.append('bin', '/apps/hermes/cgi/message');
                    data.append('stdin', 'send(' + Base64.encode(JSON.stringify(this.contact)) + ')');
                    const hostname = "https://oak-trade.cz/api/rest/rest_call.php";
                    axios.post(hostname, data, {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        })
                        .then((response) => {
                            console.log(response);
                            if (response.data.data.code == 0) {
                                this.$buefy.snackbar.open({
                                    duration: 5000,
                                    message: response.data.data.message,
                                    type: 'is-danger',
                                    position: 'is-bottom-left',
                                    actionText: 'Undo',
                                    queue: false,

                                })
                            } else {
                                this.$buefy.snackbar.open({
                                    duration: 5000,
                                    message: response.data.data.message,
                                    type: 'is-info',
                                    position: 'is-top',
                                    actionText: 'Undo',
                                    queue: false,

                                });
                                this.contact = {
                                    name: "",
                                    email: "",
                                    message: "",
                                    captcha: ""
                                }
                            }

                        }).catch(function (error) {
                            console.log(error);

                        });
                }
            }
        }

    }
</script>
<style>
    .aad {
        margin-top: 7px;
    }

    .addresses {
        height: 50vh;
        margin-top: 5px;
        position: fixed;
        width: 100vw;
        left: 25px;

    }

    .scrolling {
        overflow: scroll;
        overflow-x: hidden;
    }

    .ta {
        margin-bottom: 15px;

    }

    .ta::placeholder {
        color: #E7ECE4;
    }
.clr {
    color: #839f7d;
}
    .pc {
        margin-bottom: -20px !important;
    }

    .page-second {
        height: 50vh;
        width: calc(100% - 220px);
        position: fixed;
        bottom: 0px;
        padding-top: 20px;
    }

    .btnmaxwidth
    {
        width: 100%;
        margin-top: -15px;
        margin-bottom: 15px;
    }

    .contact {
        top: 35px;
        position: fixed;
        width: 100vw;
        left: 25px;

    }

    .number-c {
        color: white;
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 5px;
    }

    .number-2 {
        color: white;
        font-size: 1.2rem;

        margin-bottom: 5px;
    }

    .contact-people {
        margin-top: 1rem;
        margin-bottom: 1rem;
        text-align: center;
    }

    .contact-people img {
        width: 200px;
    }

    .number-3 {
        color: white;
        font-size: 1rem;

        margin-bottom: 2px;
    }

    .rl {
        position: relative
    }

    .h100 {
        height: 100%
    }

    .page-back {
        background-image: url(../assets/contact.jpg);
        position: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100vw;
        height: 50vh;
        margin-left: -177px;
        z-index: -1;
    }

    .contact-title {
        font-family: "IBM Plex Sans", sans-serif !important;
        text-align: center;
        margin-top: 2rem;
    }

    .contact-title span {
        font-family: "Roboto Serif", serif !important;
        font-weight: normal;
    }

    .contact-map {
        height: calc(100vh - 50px);
        margin-top: 25px;
    }

    @media (max-width: 768px) {
        .page-back {
            width: 103vw;
            height: 51vh;
            margin-left: -36px;
            margin-top: -130px;
            z-index: -1;
        }

        .h100 {
            height: calc(100vh);
            padding-bottom: 300px;
        }

        .contact {
            top: unset;
            bottom: 95px;
            left: 35px;

        }

        .contact-map {
            height: 300px;
            margin-top: 5px;
        }

        .contact-title {
            margin-top: 0rem;
        }
.scrollable{
    overflow-y:scroll;
}
        .addresses {

            left: 35px;

        }
    }
</style>